@import 'reset-css';
@import 'themes/index.css';
@import 'base/colors.css';
@import 'base/effects.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    @apply -tracking-tight scroll-smooth;
}

body {
    line-height: inherit;
    @apply font-brand text-text-primary fill-text-primary w-full my-0 mx-auto antialiased;
}

#sephora-booxi-iframe {
    @apply z-iframe;
}

ul li {
    @apply list-none;
}

/**
 * CSS class for when the search is open.
 *
 * @class search-isOpen
 */
.search-isOpen {
    #mobile-search-bar {
        @apply fixed top-0 h-fullViewport w-full bg-surface-primary overflow-auto overscroll-none;
    }

    #mobile-search-input {
        @apply !items-start overflow-auto;
    }

    #animation-close-fixed-search {
        @apply opacity-100 z-searchCloseAnimation;
    }
}

/**
 * CSS class newsletter.
 *
 * @class newsletter-banner
 */
.newsletter-banner {
    @apply flex flex-col gap-0.5 font-brand tracking-wide;

    .newsletter-banner-title {
        @apply text-mobileTitle3 tablet:text-title3 font-bold;
    }

    .newsletter-banner-offer {
        @apply text-body;
    }

    .newsletter-banner-description {
        @apply inline;
    }

    .newsletter-banner-info {
        @apply text-bodySmall text-text-secondary;

        span {
            @apply block my-0.75 cursor-pointer pointer-events-auto text-underline;
        }
    }

    a {
        @apply text-underline;
    }
}

/**
 * The .scrollbar-hide class is used to hide the scrollbar in the element it is applied to.
 * It uses two properties to achieve this:
 * -ms-overflow-style: This property is used for Internet Explorer and Edge browsers. The 'none' value disables the scrollbar.
 * scrollbar-width: This property is used for Firefox. The 'none' value disables the scrollbar.
 */
.scrollbar-hide {
    -ms-overflow-style: none; /* For IE and Edge */
    scrollbar-width: none; /* For Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
}

/*
  WebKit browsers (like Chrome and Safari) add default UI elements to <input type="search"> fields,
  including a magnifying glass icon, a cancel button (small "x"), and result-related decorations.
  These elements are not always desired in custom-styled inputs, as they can interfere with the
  design and layout. To ensure a consistent appearance across browsers, we hide these extra
  elements for a cleaner look.
*/

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    @apply hidden;
}

/*
  Hide the clear button ("X" icon) that appears in
  search input fields in Internet Explorer and older
  versions of Microsoft Edge. This is done to maintain
  a cleaner UI, especially when a custom design for the
  input field is being implemented.
*/

.search-input::-ms-clear {
    @apply hidden;
}

/*
  Hide the password reveal button (eye icon) in input
  fields of type "search" for Internet Explorer and
  older versions of Microsoft Edge. This is implemented
  to ensure a cleaner appearance and avoid unnecessary
  controls in the input field, especially when using
  custom styling.
*/
.search-input::-ms-reveal {
    @apply hidden;
}

.text-inherit {
    color: inherit;
}
